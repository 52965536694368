<div class="flex flex-row">
    <div class="container pt-8 m-auto w-full m-8" v-bind:style='{"max-width": image_width }'>
        <p class="my-4">
        Hello! 
        </p>
        <p class="my-4">
        You're here because I asked you for feedback on this zine. Thank you!
        </p>

        <p class="my-4">
        First, please enter your name and email so that I know who the feedback
        is from, and what email address to send the zine to when it's finished.
        </p>

        <div>
            <div class="bg-blue-300 shadow-lg border-2 p-4 my-8 relative">
                <table class="w-full">
                    <tr>
                        <td class="pr-2"> Name: </td>
                        <td>
                            <input 
                                v-model="name" 
                                @blur="createPerson($event)"
                                class="bg-blue-100 p-2 my-4 block w-full rounded border border-gray-200">
                        </td>
                    </tr>
                    <tr>
                        <td class="pr-2"> Email: </td>
                        <td>
                            <input 
                                v-model="email" 
                                @blur="createPerson($event)"
                                class="bg-blue-100 p-2 my-4 block w-full rounded border border-gray-200">
                        </td>
                    </tr>

                </table>

                <Checkmark class="absolute bottom-4 right-4 h-8" v-if="person_saved" />
                <button class="bg-black text-white px-8 py-2 rounded" @click="createPerson($event)">Save</button>
            </div>
        </div>


        <p class="mt-8 mb-8 bg-yellow-100 shadow-xl p-8 w-full">
        Now, here's the zine! You can click anywhere on a page to leave feedback.
        </p>
        <div class="bg-gray-200 p-8 mt-4 w-full" v-for="(page, index) in pages">
            <Page 
                :key="'page-' + page.zine_page"
                :number="index + 1"
                v-bind:feedbacks="feedback_by_page[page.zine_page]" 
                :page_hash="page.id"
                v-bind:id="page.zine_page" 
                v-bind:image="page_url(page.id)"></Page>

            <div v-if="page.zine_page == 'list-strategies2'">
              <div v-if="toc" class="bg-gray-200 p-1 mt-4">
                <div class="bg-white px-4 py-4">
                  <div class="text-2xl text-center py-2">Table of Contents (version with links)</div>
                  <div class="flex flex-row">
                    <div class="w-1/2 pr-4">
                      <div class="pt-1 mx-2" v-for="(page, index) in pages.slice(0, 34)">
                        <div class="relative text-sm" v-if="page.title">
                          <a :class="{
                                      'no-underline': true,
                                      'font-bold': section_starts[page.zine_page],
                                      'mt-2': section_starts[page.zine_page],
                                      'block': true,
                                     }"
                             v-bind:href="'#' + page.zine_page">{{page.title}}</a> 
                          <!-- page number -->
                          <span class="text-gray-600 absolute right-0 bottom-0">{{index + 1}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="w-1/2 pl-4">
                      <div class="pt-1 mx-2" v-for="(page, index) in pages.slice(34)">
                        <div class="relative text-sm" v-if="page.title">
                          <a :class="{
                                      'no-underline': true,
                                      'font-bold': section_starts[page.zine_page],
                                      'mt-2': section_starts[page.zine_page],
                                      'block': true,
                                     }"
                             v-bind:href="'#' + page[0]">{{page.title}}</a> 
                          <!-- page number -->
                          <span class="text-gray-600 absolute right-0 bottom-0">{{index + 32}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
        <div class="mt-8 mb-8 bg-yellow-100 shadow-xl p-8 w-full">
            <form>
                <p class="mt-8">
                Do you have any general feedback about the zine? Which parts were most interesting to you? What did you learn? Which parts were the most confusing?
                </p>

                <div class="relative">
                <textarea 
                    class="p-2 w-full h-64 rounded border border-gray-200 block my-4"
                    v-model="overall_feedback.content"></textarea>

                <Checkmark class="absolute p-2 bottom-0 right-0" v-if="overall_feedback.content && overall_feedback_saved" />
                </div>
                <button class="bg-black text-white px-8 py-2 rounded" @click="saveOverall($event)">Save general feedback</button>
            </form>
        </div>
    </div>
    <!-- spacer div to make sure there's room for the modal -->
    <div class="c-modal flex-shrink-0"> </div>
</div>
