<div 
    v-if="!feedback.deleted"   
    v-bind:class="{
           'opacity-30': feedback.checked,
           'bg-blue-100': feedback.read,
           'bg-blue-300': !feedback.read,
           'bg-purple-100': feedback.person_id == 'julia'
    }"
    @click="markRead()"
    class="text-sm stickynote relative pt-2 pb-4 px-2 rounded-lg mb-2 border border-white border-8 ">
    <input type="checkbox" v-model="feedback.checked" @change="checkFeedback()">
    <span class="ml-2 align-middle w-6 inline-block" v-html="icon()"></span>
    <span v-html="feedback.content.split('\n').join('<br>')"> </span>
    <div class="text-xs absolute text-gray-500 bottom-1 right-2">
        from {{person.name}} at 
        {{format_time(feedback.created_at)}}
        <button class="underline" @click="deleteFeedback(feedback)" >Delete</button>
    </div>
    <div class="cursor-pointer absolute top-0 right-0 p-1 text-2xl text-blue-800"
         @click="toggleStar(feedback)"
         >
         <span v-if="feedback.starred">★</span>
         <span v-else>☆</span>
    </div>
</div>
