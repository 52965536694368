<!-- TODO: maybe 500px is too much, idk -->
<div :key="feedback.zine_page" class="c-modal left-full absolute top-2 z-10 w-8 bg-gray-100 border-2 border-black">
    <div @click="$parent.close()" style="position: absolute; top: 0; right: 0" class="p-2 text-xl cursor-pointer"> &#x2715; </div>
    <form>
        <div :class="feedback.icon && 'flex'">
            <div class="p-4 m-auto">
                <span v-for="name in icons.names">
                    <input type="radio" class="hidden"
                       v-bind:id="name + '-' +page_id"
                       v-bind:value="name"
                       v-model="feedback.icon">
                    <label class="cursor-pointer block my-4 bg-white rounded-xl p-2"
                           :class="feedback.icon ? 'inline mr-4' : 'block'"
                           v-bind:for="name + '-' + page_id">
                        <span class="w-4 align-middle inline-block" v-html="icons[name].emoji"></span>
                       <span v-if="!feedback.icon" class="ml-2"> {{icons[name].text}}</span>
                    </label>
                </span>
            </div>
        </div>
        <div v-if="feedback.icon">
            <div>
              <label for="person-name">Name </label> <br>
              <input type="text" ref="name" class="p-2" id="person-name">
            </div>
            <textarea class="p-2 w-full h-64 rounded border border-gray-200 block my-4" 
                      ref="content"
                      v-model="feedback.content" v-bind:placeholder="icons[feedback.icon].prompt">
            </textarea>
            <button v-if="feedback.id !== undefined" class="bg-black text-white px-8 py-2 rounded" @click="$parent.saveFeedback($event)">
                Update
            </button>
            <button v-else class="bg-black text-white px-8 py-2 rounded" @click="$parent.saveFeedback($event, $refs.name.value)">
                Submit
            </button>
        </div>
    </form>
</div>
