<div :key="id">
  <div
    class="w-full"
    @keydown.ctrl.enter="saveFeedback($event)"
    @keydown.esc="close()"
    style="position: relative"
    tabindex="0"
  >
    <!-- tabindex is needed to make escape work -->
    <div class="relative">
      <img
        ref="image"
        v-bind:id="id"
        class="w-full"
        v-bind:src="image"
        @click="click()"
      />
      <div class="absolute" v-if="hover_feedback" v-bind:style="hover_coords()">
        <div
          class="absolute -left-4 -top-4 rounded-full w-8 h-8 bg-yellow-600 opacity-20 border border-yellow-800"
        ></div>
      </div>
    </div>
    <div class="absolute top-2 right-2 text-2xl">{{number}}</div>
    <Modal
      v-if="active_feedback"
      v-bind:feedback="active_feedback"
      page_id="id"
    />
    <div style="position: absolute; top: 0; left: 100%">
      <div
        v-for="feedback_item in feedbacks"
        class="relative stickynote cursor-pointer p-2 py-8 bg-yellow-200 rounded-lg my-2 w-64 max-h-32 overflow-hidden border border-white border-8"
        @mouseenter="hover_feedback = feedback_item"
        @mouseleave="hover_feedback = undefined"
        @click="edit_feedback($event, feedback_item)"
      >
        <span
          class="pr-2 w-8 align-middle inline-block"
          v-html="icons[feedback_item.icon].emoji"
        >
        </span>
        {{feedback_item.content}}

        <Checkmark
          class="w-full bg-green-500 text-white border-top border-yellow-500 text-center absolute p-1 right-0 bottom-0"
          v-if="feedback_item.just_saved"
        />
      </div>
    </div>
  </div>
</div>
