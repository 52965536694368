import template from './Checkmark.html';

export default {
    template: template,
    mounted() {
        setTimeout(() => {
            this.hidden = true;
        }, 1000);
    },
    data: function() {
        return {
            hidden: false
        };
    },
}
