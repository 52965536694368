import {uuidv4, saveFeedback, getPosition} from './util';
import template from './Page.html';
import * as icons from './icons.json';

export default {
    template: template,
    props: ['image', 'id', 'feedbacks', 'number', 'page_hash'],
    data() {
        return {
            icons: icons,
            active_feedback: undefined,
            hover_feedback: undefined,
        }
    },
    methods: {
        click: function() {
            if (this.active_feedback) {
                return;
            }
            const element = event.target;
            const x = event.clientX - element.getBoundingClientRect().left;
            const y = event.clientY - element.getBoundingClientRect().top;
            const width = element.getBoundingClientRect().width;
            const height = element.getBoundingClientRect().height;
            this.active_feedback = this.createEmptyFeedback(x, y, width, height);
        },

        hover_coords() {
            const img = this.$refs.image;
            return getPosition(this.hover_feedback, img.width, img.height);
        },

        edit_feedback: function(e, feedback) {
            e.preventDefault();
            this.active_feedback = feedback;
            this.active_feedback.just_saved = undefined;
        },

        close: function() {
            this.active_feedback = undefined;
        },

        createEmptyFeedback: function(x, y, width, height) {
            console.log(this.page_hash);
            return {
                person_id: this.$parent.uuid,
                zine_id: this.$parent.zine_id,
                zine_page: this.id,
                x: x,
                y: y,
                page_hash: this.page_hash,
                width: width,
                height: height,
                checked: false,
            }
        },

        saveFeedback: async function(e) {
            e.preventDefault();
            const ok = await saveFeedback(this.active_feedback);
            if (ok) {
                this.active_feedback.just_saved = true;
            }
            this.$parent.addFeedback(this.active_feedback);
            this.close();
        },
    },
}
