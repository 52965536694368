import {uuidv4} from './util';
import template from './Modal.html';
import * as icons from './icons.json';

export default {
    template: template,
    props: ['feedback', "page_id"],
    mounted: function () {
        console.log(this.icons);
    },
    updated: function() {
        if (this.$refs.content && !this.focused) {
            this.$refs.content.focus();
            this.focused = true;
        }
    },
    data() {
        return {
            icons: icons,
        }
    },
}
