import {Feedback} from "./types"; 
// @ts-ignore
import template from './Admin.html';

export default {
    template: template,
    props: ['pages_list', 'zine_id', 'image_width'],
    data() {
        return {
            pages: [],
            feedback_by_page: {},
            people: {},
            loaded: false,
            show_checked: false,
            show_heart_lightbulb: true,
        }
    },

    async mounted() {
        this.load();
    },

    methods: {
        async load() {
            console.log('loading');
            this.feedback_by_page = {};
            // @ts-ignore
            this.pages = await (await fetch("/api/zine_version?zine_id=" + this.zine_id)).json();
            // @ts-ignore
            const response = await (await fetch("/api/all?zine_id=" + this.zine_id)).json();
            for (const feedback of response.feedbacks) {
                this.addFeedback(feedback);
            }
            // @ts-ignore
            this.people = response.people;
            this.loaded = true;

        },
        person(feedback: Feedback): Person {
            return this.people[feedback.person_id] || {}
        },
        addFeedback(feedback: Feedback) {
            const page = feedback.zine_page;
            if (!this.feedback_by_page[page]) {
                this.feedback_by_page[page] = {};
            }
            this.feedback_by_page[page][feedback.id] = feedback;
        },
    },
}
