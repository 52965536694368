<div>
    <form @submit.prevent="checkPassword()">
        Password:
        <input v-model="password" name="password" 
               class="bg-blue-100 p-2 my-4 block w-full rounded border border-gray-200">
        <button
            type="submit"
            class="bg-blue-500 text-white p-2 my-4 block w-full rounded border border-gray-200"
            >
            Login
        </button>
            <div v-if="failed" class="text-red-500">
                Invalid password
            </div>
    </form>
</div>
