import { Person, Feedback } from "./types";

/********************************************************
 * utility functions
 *********************************************************/

/*
 * how local storage is structured:
 * name -> string
 * email -> string
 * uuid -> string
 * feedback -> serialized feedback hash (key: page_id, value: array of feedback)
 */

export function uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function getUUID(): string {
    var uuid = localStorage.getItem("uuid");
    if (!uuid) {
        uuid = uuidv4();
        localStorage.setItem("uuid", uuid);
    }
    return uuid;
}

export async function saveFeedback(feedback: Feedback) {
    if (!feedback.id) {
        feedback.id = uuidv4();
    }
    const resp = await fetch("/api/feedback", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(feedback),
        signal: AbortSignal.timeout(1000),
    });
    return resp.ok;
}

export async function createPerson(person: Person) {
    const result = await fetch("/api/person", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(person),
    });
    return result;
}

export function getPosition(feedback: Feedback, currWidth: number, currHeight: number) {
    const currX = feedback.x * currWidth / feedback.width;
    const currY = feedback.y * currHeight / feedback.height;
    return {
        left: currX + 'px',
        top: currY + 'px',
    };
}
