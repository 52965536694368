import { uuidv4, getUUID, saveFeedback, createPerson } from './util';
import template from "./Feedback.html";

export default {
    template: template,
    props: ['pages_list', 'zine_id', 'image_width', 'toc'],
    data() {
        return {
            name: "",
            email: "",
            uuid: "",
            pages: [],
            overall_feedback: {},
            overall_feedback_saved: false,

            feedback_by_page: {},
            person_saved: false,
            section_starts: {
                "first-steps": true,
                "get-organized": true,
                "investigate": true,
                'research': true,
                "simplify": true,
                'get-unstuck': true,
                "improve-your-toolkit": true,
                "after-its-fixed": true,
            },
        }
    },
    async mounted() {
        this.pages = await (await fetch("/api/zine_version?zine_id=" + this.zine_id)).json();
        this.uuid = getUUID();
        const resp = await (await fetch("/api/all?person_id=" + this.uuid)).json()
        for (const feedback of resp.feedbacks) {
            this.addFeedback(feedback);
        }

        // set name/email
        if (Object.values(resp.people).length > 0) {
            const person = Object.values(resp.people)[0];
            this.name = person.name;
            this.email = person.email;
        }

        if (this.feedback_by_page['overall']) {
            this.overall_feedback = Object.values(this.feedback_by_page['overall'])[0];
        } else {
            this.overall_feedback = {
                id: uuidv4(),
                icon: 'none',
                person_id: this.uuid,
                zine_id: this.zine_id,
                zine_page: 'overall',
                checked: false,
            };
        }
    },
    methods: {
        setOverallFeedback(event) {
            // TODO: broken
            event.preventDefault();
            this.feedback['overall'] = this.overall_feedback;
            // redirect to thank you page
            window.location.href = "/thankyou.html";
        },
        async createPerson(event) {
            this.person_saved = false;
            event.preventDefault();
            const result = await createPerson({
                    name: this.name,
                    email: this.email,
                    id: this.uuid
            });
            if (result.ok) {
                this.person_saved = true;
            }
        },

        addFeedback(feedback) {
            const page = feedback.zine_page;
            if (!this.feedback_by_page[page]) {
                this.feedback_by_page[page] = {};
            }
            this.feedback_by_page[page][feedback.id] = feedback;
        },

        async saveOverall(e) {
            e.preventDefault();
            this.overall_feedback_saved = false;
            const ok = await saveFeedback(this.overall_feedback);
            if (ok) {
                this.overall_feedback_saved = true;
            }
        },

        page_url(uuid) {
            return `/api/pages/${uuid}`;
        },
    },
}
