import template from "./AdminFeedback.html";
import { saveFeedback } from "./util";
import * as icons from "./icons.json";

export default {
  template: template,
  props: ["feedback", "person"],
  data: function () {
    return {
      icons: icons,
    };
  },
  methods: {
    format_time(timestamp) {
      return new Date(timestamp * 1000).toLocaleString();
    },
    saveFeedback() {
      saveFeedback(this.feedback);
    },
    async checkFeedback() {
      try {
        const ok = await saveFeedback(this.feedback);
        if (!ok) {
          throw new Error();
        }
      } catch (err) {
        console.log("checkFeedback failed", err);
        this.feedback.checked = false;
      }
    },
    icon() {
      const icon = this.icons[this.feedback.icon];
      if (icon) {
        return icon.emoji;
      }
      // sometimes there's no icon because it's overall feedback
      return "";
    },
    deleteFeedback() {
      this.feedback.deleted = true;
      this.saveFeedback();
    },
    markRead() {
      this.feedback.read = true;
      this.saveFeedback();
    },
    toggleStar() {
      this.feedback.starred = !this.feedback.starred;
      this.saveFeedback();
    },
  },
};
