import template from './Login.html';

export default {
    template: template,
    data() {
        return {
            password: '',
            failed: false,
        }
    },
    methods: {
        async checkPassword() {
            const response = await fetch("/check_password", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ password: this.password })
            });
            const data = await response.json();
            if (data.valid) {
                window.location.reload();
            } else {
                this.failed = true;
            }
        }
    },
}
